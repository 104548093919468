<script>
import { FormWizard, TabContent } from "@anivive/vue3-form-wizard";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKEditor from "@ckeditor/ckeditor5-vue";
import Loader from '@/components/widgets/loader';
import Swal from 'sweetalert2';
import router from '@/router';

import brandGetall from '../../../state/api/brandGetAll';
import getFindByBrandId from '../../../state/api/getFindByBrandId';

import productGetFindById from "../../../state/api/productGetFindById";
//import getFindByCategoryIdList from '../../../state/api/getFindByCategoryIdList';
import LanguageGetAll from "../../../state/api/LanguageGetAll";
import productSaveOrUpdate from "../../../state/api/productSaveOrUpdate";


/**
 * Form wizard component
 */
export default {
  page: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  //components: { Layout, PageHeader, FormWizard, TabContent,ckeditor: CKEditor.component,Loader },
  components: { Layout, PageHeader, FormWizard, TabContent,Loader },
  
  data() {
    return {
      title: "Edit Product",
      items: [
        {
          text: "Products",
          href: "/product/product-list",
        },
        {
          text: "Edit Product",
          active: true,
        },
      ],
      id:this.$route.params.id,
      catagoryList : [],
      languageList : [],
      brandList : [],
      brandParentId:null,
      productDetail :{
        languageId:"",
        categoryId:"",
        brandId:"",
        name:"",
        shortDescription:"",
        isHtml:"",
        longDescription:"",
        orderBy:"",
        youTubeCode:"",
        isActive:"",
        isFavoriteFlag:false,
        isNewFlag:false,
      }
    };
  },
  methods:{
    async brandGetall(){
        const {getBrandGetAll} = brandGetall()
        const response = await getBrandGetAll();
        this.brandList = response.data;             
    },
    async productGetFindById(){
        const {getProductDetail} = productGetFindById()
        const response = await getProductDetail(this.id);
        this.productDetail = response.data;

        const {catFindByBrandId} = getFindByBrandId()
        const response2 = await catFindByBrandId(this.productDetail.brandId,this.brandParentId);
        this.catagoryList = response2.data.categoryList; 
    },
    // async getCatagoryList(){
    //     const {CategoryIdList} = getFindByCategoryIdList()
    //     const response = await CategoryIdList();
    //     this.catagoryList = response.data;           
    // },
    async LanguageGetAll(){
        const {LanguageGet} = LanguageGetAll()
        const response = await LanguageGet();
        this.languageList = response.data;           
    },
    async catFilter(){
        this.catagoryList =[];
        const {catFindByBrandId} = getFindByBrandId()
        const response = await catFindByBrandId(this.productDetail.brandId,this.brandParentId);
        this.catagoryList = response.data.categoryList; 
    },
    getAddProduct(){
        let update = {
            "Id":this.id,
            "BrandId":this.productDetail.brandId,
            "CategoryId":this.productDetail.categoryId,
            "Name":this.productDetail.name,
            "ShortDescription":this.productDetail.shortDescription,
            "LongDescription":this.productDetail.longDescription,
            "IsActive":this.productDetail.isActive,
            "IsHtml":this.productDetail.isHtml,
            "OrderBy":this.productDetail.orderBy,
            "HomePageOrderBy":this.productDetail.homePageOrderBy,
            "LanguageId":this.productDetail.languageId,
            "YoutubeCode":this.productDetail.youTubeCode,
            "IsFavoriteFlag":this.productDetail.isFavoriteFlag,
            "IsNewFlag":this.productDetail.isNewFlag,            
        }
        async function updateProduct() {
            const {saveOrUpdate} = productSaveOrUpdate()
            await saveOrUpdate(update).
            then(res=>{
                if(res.isSuccess){
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    router.push({name:'product-list'})
                }
            })
        }
        updateProduct();
    },
  },
  mounted(){
    this.productGetFindById();
    //this.getCatagoryList();
    this.LanguageGetAll();
    this.brandGetall();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
        <Loader :loading="productDetail">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="row">
                            <div class="col-lg-10">
                                <div class="card-body">
                                    <form-wizard color="#556ee6">
                                        <tab-content icon="mdi mdi-account-circle">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="languageId"
                                                            >{{$t('user.languageSelect')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="productDetail.languageId">
                                                                <option v-for="language in languageList" :key="language.id" :value="language.id">{{language.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="selectedBrand"
                                                            >{{$t('user.BrandSelect')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="productDetail.brandId" @change="catFilter">
                                                                <option v-for="brand in brandList" :key="brand.id" :value="brand.id">{{brand.title}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="categoryId"
                                                            >{{$t('user.MainCategory')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="productDetail.categoryId">
                                                                <option v-for="cat in catagoryList" :key="cat.id" :value="cat.id">{{cat.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="CategoryTitle"
                                                            >{{$t('user.CategoryTitle')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="text"
                                                            name="CategoryTitle"
                                                            class="form-control"
                                                            v-model="productDetail.name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="shortDescription"
                                                            >{{$t('user.shortDescription')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="text"
                                                            name="shortDescription"
                                                            class="form-control"
                                                            v-model="productDetail.shortDescription"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isHtml"
                                                            >{{$t('user.isHtml')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="productDetail.isHtml" name="isHtml" switch>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="longDescription"
                                                            >{{$t('user.longDescription')}}</label
                                                        >
                                                        <div class="col-md-9">                                                
                                                            <!-- Editor -->
                                                            <textarea v-model="productDetail.longDescription" rows="10" class="w-100"></textarea>
                                                            <!-- <ckeditor v-model="productDetail.longDescription" :editor="editor">{{ productDetail.longDescription }}</ckeditor> -->
                                                        </div>
                                                    </div> 
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="orderBy"
                                                            >{{$t('user.OrderNo')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="number"
                                                            name="OrderNo"
                                                            class="form-control"
                                                            v-model="productDetail.orderBy"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="youTubeCode"
                                                            >{{$t('user.youTubeCode')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="number"
                                                            name="youTubeCode"
                                                            class="form-control"
                                                            v-model="productDetail.youTubeCode"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isActive"
                                                            >{{$t('user.Active')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="productDetail.isActive" name="check-button" switch >
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="IsFavoriteFlag"
                                                            >{{$t('user.IsFavoriteFlag')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="productDetail.isFavoriteFlag" name="check-button" switch>
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isNewFlag"
                                                            >{{$t('user.isNewFlag')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="productDetail.isNewFlag" name="check-button" switch>
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="mt-2">
                                                        <button type="submit" class="btn btn-primary me-1" @click="getAddProduct">
                                                        {{$t('user.SaveChanges')}}
                                                        </button>
                                                    </div>
                                                </div>                                    
                                                <!-- end col -->
                                            </div>
                                            <!-- end row -->
                                        </tab-content>
                                    </form-wizard>
                                </div>
                                <!-- end card-body -->
                            </div>
                        </div>            
                    </div>
                    <!-- end card -->
                </div>
                </div>
                <!-- end row -->
        </Loader>    
  </Layout>
</template>



