import apiPath from './apiPath'
import {ref} from 'vue'
import { useRouter } from 'vue-router';

const productSaveOrUpdate =()=>{
    const hata = ref(null);
    const router = useRouter();
    const path = apiPath;
    let users = JSON.parse(localStorage.getItem('user'));
    const saveOrUpdate = async(update)=>{
        hata.value = null;
        try {
            let res ="";
            if(update.Id != "null"){
                res = await fetch(path + "product/saveOrUpdate", {
                    method: 'POST',
                    headers: {
                      'Content-type': 'application/json; charset=UTF-8'
                    },
                    
                    body: JSON.stringify({
                        "Id": update.Id,                        
                        "CategoryId": update.CategoryId,
                        "BrandId": update.BrandId,
                        "Name": update.Name,
                        "ShortDescription": update.ShortDescription,
                        "LongDescription": update.LongDescription,
                        "IsActive":update.IsActive,
                        "IsHtml":update.IsHtml,
                        "IsNewFlag":update.IsNewFlag,
                        "IsFavoriteFlag":update.IsFavoriteFlag,
                        "OrderBy":update.OrderBy,
                        "LanguageId":update.LanguageId,
                        "YoutubeCode":update.YoutubeCode,
                        "TokenKey": localStorage.getItem('token'),
                        "UserId":users.id,
                        "RequestMethodName" :"/api/product/saveOrUpdate",
                        "RequestMethodType" : "POST"
                    }), 
                })
            }
            else{
                res = await fetch(path + "product/saveOrUpdate", {
                    method: 'POST',
                    headers: {
                      'Content-type': 'application/json; charset=UTF-8'
                    },
                    
                    body: JSON.stringify({
                        "BrandId": update.BrandId,
                        "CategoryId": update.CategoryId,
                        "Name": update.Name,
                        "ShortDescription": update.ShortDescription,
                        "LongDescription": update.LongDescription,
                        "IsActive":update.IsActive,
                        "IsHtml":update.IsHtml,
                        "IsNewFlag":update.IsNewFlag,
                        "IsFavoriteFlag":update.IsFavoriteFlag,
                        "OrderBy":update.OrderBy,
                        "LanguageId":update.LanguageId,
                        "YoutubeCode":update.YoutubeCode,
                        "DocumentUrl":"",
                        "TokenKey": localStorage.getItem('token'),
                        "UserId":users.id,
                        "RequestMethodName" :"/api/product/saveOrUpdate",
                        "RequestMethodType" : "POST"
                    }), 
                })
            }
            
            const data = await res.json();
            if(data.errorMessage == "Error, token expired."){
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.push({name:'login'})              
            }
            else{
                if(data.isSuccess){
                    return data
                }
            }
        } catch (error) {
            hata.value = error.message
        }
    }
    
    return {saveOrUpdate}
}

export default productSaveOrUpdate